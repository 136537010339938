<template>
	<div>
		<div class="header filter">
			<div class="top top_mafi"><!--trans-->
				<div class="util shadow">
					<button
						@click="goBack"
						class="arrow_white"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<div class="save">
					<button
						@click="postCartel"
						class="btn_save"
					>{{  $language.common.open_request }}</button>
				</div>
			</div>
		</div>
		<div class="section_wrap pt-50">
			<!-- 커버등록 -->
			<div class="cartel_cover">
				<label class="box pa-5-10 radius-10 position-absolute size-px-12 bg-white-half" style="right: 10px; top: 10px; z-index: 9">
					<v-icon small class="">mdi-camera</v-icon>
					{{ $language.cartel.regist_cover_image }}
					<InputFileNew class="fileform_hidden" accept="image/*" @change="setFile($event, 'mobile')" ref="file" file_upload_code_info="CM00700021" />
				</label>
				<div
					v-if="item_cartel.cartl_mobile_cover_img_url"
					class="cover_background"
				>
					<img
						:src="item_cartel.cartl_mobile_cover_img_url"
						style="width: 100%"
					/>
				</div>

				<div
					v-else
					class="text-center size-px-12 text-shadow flex-column justify-center full-height color-white"
				>{{ $language.cartel.info_request_cover_image }} <br/> <span class="color-gray">* {{ $language.cartel.info_request_cover_image_size1 }}</span></div>

				<label class="box-profile-outline">
					<span class="box-profile-inline">
						<v-icon class="color-white">mdi-camera-outline</v-icon>
						{{ $language.cartel.regist_logo_image }}
					</span>
					<InputFileNew type="file" class="fileform_hidden" accept="image/*" @change="setFile($event, 'profile')" ref="file" file_upload_code_info="CM00700021" />
					<img
						v-if="item_cartel.cartl_profle_img_url"
						:src="item_cartel.cartl_profle_img_url"
						class="full-width object-cover"
					/>
				</label>
			</div>

			<div class="container">
				<div class="subindex_mainbox">

					<div class="input_area mt-30">
						<div class="bright_input">
							<div class="flrex-row justify-space-between">
								<!-- 웹 커버 이미지 -->
								<label for="cartelName">{{  $language.cartel.cover_web }}</label>

								<label class="box pa-5-10 radius-10 size-px-10 bg-white-half">
									<v-icon small class="">mdi-camera</v-icon>
									{{ $language.cartel.regist_cover_image }}
									<InputFileNew class="fileform_hidden" accept="image/*" @change="setFile($event, 'cover')" ref="file" file_upload_code_info="CM00700021" />
								</label>
							</div>

							<div class="input_utill overflow-hidden radius-10" style="height: 50px; background-color: #eee; padding: 0; border: 1px solid var(--light-Gray02)">

								<img
									v-if="item_cartel.cartl_cover_img_url"
									:src="item_cartel.cartl_cover_img_url"
									class=" radius-10 object-cover"
								/>
								<div
									v-else
									class="text-center"
								>
									<img
										:src="require('@/assets/image/cartel-cover-thumb.png')"
										style="width: 30px;" class="mt-10"
									/>
								</div>
							</div>

							<div class="input_area" style="padding-top: 10px">
								<p class="input_desc2 size-px-12">
									{{  $language.common.text_tip4 }}
								</p>
							</div>
						</div>
					</div>

					<!-- 카르텔이름 -->
					<div class="input_area">
						<div class="bright_input">
							<label for="cartelName">{{  $language.cartel.name }}</label>
							<div class="input_utill">
								<input
									v-model="item_cartel.cartl_name"
									type="text" id="cartelName" :placeholder="$language.cartel.name_require" maxlength="20"
								>
								<div class="input_utill_area">
									<div class="btn_del">
										<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
										<button
											v-if="item_cartel.cartl_name"
											@click="item_cartel.cartl_name = ''"
											class="active"
										><span class="hide">{{  $language.common.close }}</span></button>
									</div>
									<!-- TODO : 텍스트 수 카운트 필요 -->
									<span class="input_text_count">{{ item_cartel.cartl_name.length }}/20</span>
								</div>
							</div>
						</div>
					</div>
					<!-- //카르텔이름 -->
					<!-- 카르텔설명 -->
					<div class="input_area">
						<div class="bright_input">
							<label for="cartelDesc">{{  $language.common.explain_cartel }}</label>
							<div class="input_utill">
								<input
									v-model="item_cartel.cartl_desctn"
									type="text" id="cartelDesc" :placeholder="$language.cartel.explain_require" maxlength="40"
								>
								<div class="input_utill_area">
									<div class="btn_del">
										<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
										<button
											v-if="item_cartel.cartl_desctn"
											class="active"
											@click="item_cartel.cartl_desctn = ''"
										><span class="hide">{{  $language.common.close }}</span></button>
									</div>
									<!-- TODO : 텍스트 수 카운트 필요 -->
									<span class="input_text_count">{{ item_cartel.cartl_desctn.length }}/40</span>
								</div>
							</div>
						</div>
					</div>
					<!-- //카르텔설명 -->

					<div class="input_area">
						<div class="bright_input">
							<label for="cartelDesc">{{  $language.common.cartel_sns}}</label>
							<div
								class=" flex-row items-center"
								v-for="i in sns_count"
								:key="'sns_' + i"
							>
								<div
									class="input_utill position-relative mr-5"
								>
									<input
										v-model="item_cartel.member_social_url_list[i - 1].member_social_url"
										type="text" :placeholder="$language.cartel.sns_require" maxlength="40"
									>
								</div>

								<button
									v-show="i > 1"
									@click="removeSns(i - 1)"
								><v-icon>mdi-minus-circle</v-icon></button>
							</div>
						</div>
					</div>

					<div class="btn_area">
						<button
							class="btn_l btn_fill_blue flex-row justify-center items-center"
							@click="snsCount"
						><span>SNS </span> <v-icon small class="color-white">mdi-plus</v-icon></button>
					</div>

					<!-- 가입승인 -->
					<div class="input_area">
						<div class="flex align_items_center justify_content_between">
							<div class="tit mb-0">{{  $language.auth.auto_join_approval }}</div>
							<p class="toggle-group h_unset" @click="item_cartel.entry_approval_fg = !item_cartel.entry_approval_fg">
								<!-- TODO : 수신 허용시 .on 클래스 추가 -->
								<span class="btn-toggle " :class="{ on: item_cartel.entry_approval_fg}"><em class="hide">{{  $language.common.join_confirm.name }}</em></span>
							</p>
						</div>
						<p class="input_desc">
							{{  $language.cartel.boss_approval_need_join }}
						</p>
					</div>
					<!-- //가입승인 -->

					<div class="input_area">
						<h4 class="tit">{{  $language.cartel.interested_require }}</h4>
					</div>
					<ul class="ct_interest">
						<li>
							<div class="bright_radio interest_area">
								<input
									v-model="item_cartel.concern_sphere_code"
									value="CM00400001"
									type="radio" id="music"
								>
								<label for="music">
									<strong>Music</strong>
									<p>{{ $language.interested.music }}</p>
								</label>
							</div>
						</li>
						<li>
							<div class="bright_radio interest_area">
								<input
									v-model="item_cartel.concern_sphere_code"
									value="CM00400002"
									type="radio" id="art"
								>
								<label for="art">
									<strong>Art</strong>
									<p>{{ $language.interested.art }}</p>
								</label>
							</div>
						</li>
						<li>
							<div class="bright_radio interest_area">
								<input
									v-model="item_cartel.concern_sphere_code"
									value="CM00400003"
									type="radio" id="fashion"
								>
								<label for="fashion">
									<strong>Fashion</strong>
									<p>{{ $language.interested.fashion }}</p>
								</label>
							</div>
						</li>
						<li>
							<div class="bright_radio interest_area">
								<input
									v-model="item_cartel.concern_sphere_code"
									value="CM00400004"
									type="radio" id="influence"
								>
								<label for="influence">
									<strong>Influence</strong>
									<p>{{ $language.interested.influence }}</p>
								</label>
							</div>
						</li>
					</ul>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
import InputFileNew from "@/components/InputFileNew";
export default {
	name: 'CartelRequest'
	,
	components: {InputFileNew},
	props: ['callBack', 'user']
	, data: function(){
		return {
			program: {
				name: 'CartelRequest'
				, title: this.$language.cartel.cartel_request
				, type: 'mypage'
				, not_footer: true
				, not_header: true
				, is_next: true
				, callBack: {
					name: 'cartel'
					, step: 1
				}
			}
			, item_cartel: {
				step: 1
				, cartl_name: ''
				, cartl_desctn: ''
				, entry_approval_fg: true
				, concern_sphere_code: ''
				, user_id: this.user.id
				, member_social_url_list: [
					{ member_social_url: ''}
				]
			}
			, cartel_img: ''
			, sns_count: 1
		}
	}
	, methods: {

		setFile: function(e, type){

			if(type == 'cover') {
				this.$set(this.item_cartel, 'cartl_cover_img_url', e)
			}else if(type == 'mobile'){
				this.$set(this.item_cartel, 'cartl_mobile_cover_img_url', e)
			}else{
				this.$set(this.item_cartel, 'cartl_profle_img_url', e)
			}
		}
		, postFile: async function(){
			return true
		}
		, postCartel: async  function(){
			console.log('postCartel', this.item_cartel)

			if(!this.item_cartel.cartl_mobile_cover_img_url){
				this.$bus.$emit('notify', { type: 'error', message: this.$language.cartel.image_require})
				return false
			}else if (!this.item_cartel.cartl_name || this.item_cartel.cartl_name == '') {
				this.$bus.$emit('notify', { type: 'error', message: this.$language.cartel.name_require})
				return false
			} else if (!this.item_cartel.cartl_desctn || this.item_cartel.cartl_desctn == '') {
				this.$bus.$emit('notify', { type: 'error', message: this.$language.cartel.explain_require})
				return false
			} else if (!this.item_cartel.member_social_url_list[0].member_social_url || this.item_cartel.member_social_url_list[0].member_social_url == '') {
				this.$bus.$emit('notify', { type: 'error', message: this.$language.cartel.sns_require})
				return false
			}

			if (!this.item_cartel.concern_sphere_code) {
				this.$bus.$emit('notify', { type: 'error', message: this.$language.cartel.interested_require})
				return false
			}

			if(await this.postFile()){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_request
						, data: {
							member_number: this.user.member_number
							, cartl_name: this.item_cartel.cartl_name
							, cartl_desctn: this.item_cartel.cartl_desctn
							, entry_approval_fg: !this.item_cartel.entry_approval_fg ? 'Y' : 'N'
							, concern_sphere_code: this.item_cartel.concern_sphere_code
							, cartl_profle_img_url: this.item_cartel.cartl_profle_img_url
							, cartl_cover_img_url: this.item_cartel.cartl_cover_img_url
							, cartl_mobile_cover_img_url: this.item_cartel.cartl_mobile_cover_img_url
							, member_social_url_list: this.item_cartel.member_social_url_list
						}
						, name: 'postCartel'
						, type: true
					})

					if(result.success){
						this.$emit('to', { name: 'CartelRequestList'})
					}else{
						throw result.message
					}

				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}

		,goBack: function(){
			this.$emit('goBack')
		}
		, snsCount: function(){
			if(this.sns_count < 5){
				this.item_cartel.member_social_url_list.push({
					member_social_url: ''
				})
				this.sns_count++
			}
		}
		, removeSns: function(i){
			this.item_cartel.member_social_url_list.splice(i, 1)
			this.sns_count--
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		document.body.scrollTop = 0
	}
}
</script>

<style>
	.box-profile-outline {
		position: absolute; left: calc(50% - 45px); bottom: -45px; z-index: 9;
		display: flex; flex-direction: column; align-items: center; justify-content: center;
		background-color: #2C2C2C; width: 95px; height: 95px;
		border-radius: 100%;
		overflow: hidden;
	}

	.box-profile-inline {
		position: absolute; left: 0; top: 0; z-index: 9;
		display: flex; flex-direction: column; align-items: center; justify-content: center;
		width: 95px; height: 95px;
		font-size: 12px;
		border-radius: 100%;
		color: var(--white);
	}
	.box-profile-outline img {
		object-fit: cover;
	}
</style>